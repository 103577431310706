import man from "../../assets/images/levlexowcompressed.png";
import certificate from "../../assets/images/tuv-zertifikat.png";
import {loggedInUser} from "../../utils/helpers";
import { ReactComponent as LogoSvg } from "../../assets/icons/logo.svg";
import { ReactComponent as LogoDarkSvg } from "../../assets/icons/logo-dark.svg";
import { ReactComponent as LegalTrustLogo } from "../../assets/icons/legaltrust.svg";
import { ReactComponent as LegalTrustDarkLogo } from "../../assets/icons/legal-trust-logo-dark.svg";
import { ReactComponent as Star } from "../../assets/icons/star.svg";

export default function AppointmentDashboard() {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        (loggedInUser && loggedInUser.editable_by_data_protection_officer) && process.env.REACT_APP_SHOW_PREMIUM_SELLING === "true" ?
            <div className="w-full md:!max-w-[326px] lg:!w-[326px] !h-[460px] 4xs:!h-[420px] s:!h-[395px] md:!h-full md:!min-h-[415px] relative bg-ere-content-blue rounded flex flex-col overflow-hidden light-blue-border">
                <div className="border-b-[1px] border-ere-link-blue/40 w-[100%] px-[22px] py-[16px] flex items-center justify-start">
                    <LogoDarkSvg className="inline"/>
                    <span className="font-[700] text-[11.2px] mx-3 text-white mt-[10px]">x</span>
                    <LegalTrustDarkLogo className="inline mt-2"/>
                    <Star className="ml-auto scale-[1.2]" />
                </div>
                <div className="p-[22px] pt-[16px] ">
                <h1 className="text-[20px] font-bold text-white leading-[24px] w-max z-10 mb-[8px] ">DatenschutzPro: Beratung</h1>
                    <span className=" text-sm font-normal text-white  leading-normal  z-10 text-left relative block w-[80%]">
                        Als Kunde des DatenschutzPro: <br/>Beratungspakets können Sie ab sofort Ihr Onboarding und Ihre Beratungsstunden bei Legaltrust buchen sowie Soforthilfe bei DSGVO Notfälle anfordern.</span>
                    <div className="h-20 flex-col align-top text-white">
                        <div className="text-xs mt-[20px] xl:mt-[20px] flex flex-col leading-normal">
                            <span className="font-semibold">Lev Lexow<br/></span>
                            <span className="font-normal break-keep z-10 -mt-[1px]">TÜV-zertifizierter DSB</span>
                            <img className="w-[100px] h-[37px] shadow-xs  z-20"
                                 src={process.env.REACT_APP_URL + certificate}
                                 alt="TÜV-zertifizierter DSB"/>
                        </div>
                    </div>

                    <img className="w-[174px] -bottom-[16px] absolute -right-[22px]"
                         src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
                    <button id="erecht24-dashboard-appointment"
                            className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 absolute text-sm  uppercase tracking-[0.233px] bottom-[24px] left-[24px] font-normal hover:font-normal"
                            onClick={() => openInNewTab("https://calendly.com/legaltrust")}
                    >
                        Jetzt Termin buchen
                    </button>
                </div>
            </div>
        :
            <div className="w-full md:!max-w-[326px] lg:!w-[326px] !h-[500px] s:!h-[395px] md:!h-full md:!min-h-[435px] lg:!min-h-[435px] relative bg-white rounded flex flex-col overflow-hidden light-blue-border">
                <div className="border-b-[1px] border-ere-link-blue/40 w-[100%] px-[22px] py-[16px] flex items-center justify-start">
                    <LogoSvg className="inline"/>
                    { process.env.REACT_APP_SHOW_PREMIUM_SELLING === "true" ?
                        <>
                            <span className="font-[700] text-[11.2px] mx-3 mt-[10px]">x</span>
                            <LegalTrustLogo className="inline mt-2"/>
                        </>
                    :
                        ""
                    }
                </div>
                <div className="p-[22px] pt-[16px] ">
                    {process.env.REACT_APP_SHOW_PREMIUM_SELLING === "true" ?
                        <>
                            <h1 className="text-[20px] font-bold text-ere-content-blue leading-[24px] w-max z-10 mb-[8px] ">Sie brauchen Hilfe?</h1>
                            <span className=" text-sm font-normal text-ere-grey-alternative leading-normal  z-10 text-left relative block w-[80%]">
                                Mehr Beratung, einen eigenen Datenschutzbeauftragten, ein ausführliches Onboarding? Kein Problem, mit dem DatenschutzPro Beratungspaket inkl.DSB sind Sie rundum gut beraten.
                            </span>
                            <div className="h-20 flex-col align-top text-ere-grey-alternative">
                                <div className="text-xs mt-[20px] xl:mt-[20px] flex flex-col leading-normal">
                                    <span className="font-semibold">Lev Lexow<br/></span>
                                    <span className="font-normal break-keep z-10 -mt-[1px]">TÜV-zertifizierter DSB</span>
                                    <img className="w-[100px] h-[37px] shadow-xs  z-20" src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                                </div>
                            </div>
                            <img className="w-[174px] -bottom-[16px] absolute -right-[32px]" src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
                            <button id="erecht24-dashboard-appointment" className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 absolute text-sm  uppercase tracking-[0.233px] bottom-[24px] left-[24px] font-normal hover:font-normal"
                                    onClick={() => openInNewTab(process.env.REACT_APP_PREMIUM_SELLING_URL)}
                            >
                                Jetzt Upgrade buchen
                            </button>
                        </>
                    :
                        <>
                            <h1 className="text-[20px] font-bold text-ere-content-blue leading-[24px] w-max z-10 mb-[8px] ">Sie haben Fragen?</h1>
                            <span className=" text-sm font-normal text-ere-grey-alternative leading-normal  z-10 text-left relative block w-[80%]">
                                Kein Problem, Ihr persönlicher Datenschutzbeauftragter Lev Lexow steht Ihnen zur Verfügung.
                            </span>
                            <div className="h-20 flex-col align-top text-ere-grey-alternative">
                                <div className="text-xs mt-[20px] xl:mt-[20px] flex flex-col leading-normal">
                                    <span className="font-semibold">Lev Lexow<br/></span>
                                    <span className="font-normal break-keep z-10 -mt-[1px]">TÜV-zertifizierter DSB</span>
                                    <img className="w-[100px] h-[37px] shadow-xs  z-20" src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                                </div>
                            </div>
                            <img className="w-[174px] -bottom-[16px] absolute -right-[32px]" src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
                            <button id="erecht24-dashboard-appointment" className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 absolute text-sm  uppercase tracking-[0.233px] bottom-[24px] left-[24px] font-normal hover:font-normal"
                                    onClick={() => openInNewTab("https://calendly.com/legaltrust")}
                            >
                                Termin vereinbaren
                            </button>
                        </>
                    }
                </div>
            </div>
    )
}