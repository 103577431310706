import man from "../../assets/images/levlexowcompressed.png";
import certificate from "../../assets/images/tuv-zertifikat.png";
import {loggedInUser} from "../../utils/helpers";

export default function Appointment() {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        (loggedInUser && loggedInUser.editable_by_data_protection_officer) && process.env.REACT_APP_SHOW_PREMIUM_SELLING === "true" ?
            <div className="w-[250px] h-[459px] relative bg-ere-content-blue shadow-sm rounded flex flex-col overflow-hidden p-[24px] text-center items-center">
                <div className="text-2xl font-bold text-white leading-[24px] w-max">DatenschutzPro:<br/> Beratung</div>
                <div className="text-sm font-normal text-white leading-normal mt-1 ">
                    Als Kunde des DatenschutzPro: <br/>Beratungspakets können Sie ab sofort Ihr Onboarding und Ihre Beratungsstunden bei Legaltrust buchen sowie Soforthilfe bei DSGVO Notfälle anfordern.
                </div>
                <div className="h-20 flex-col self-center align-top text-white">
                    <div className="mt-[10px]">
                        <span className="font-semibold mt-6 text-xs">Lev Lexow </span>
                        <span className="font-normal break-keep text-xs block">TÜV-zertifizierter DSB</span>
                        <img className="w-[100px] h-auto shadow-xs inline" src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                    </div>
                </div>
                <img className="w-[167px] top-[300px] absolute" src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
                <button id="erecht24-question-catalog-appointment"
                        className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 relative text-sm top-[107px] uppercase tracking-[0.233px] font-black"
                        onClick={() => openInNewTab("https://calendly.com/legaltrust")}
                >
                    Jetzt Termin buchen
                </button>
            </div>
        :
            <div className="w-[250px] h-[459px] relative bg-white shadow-sm rounded flex flex-col overflow-hidden p-[24px] text-center items-center">
                { process.env.REACT_APP_SHOW_PREMIUM_SELLING === "true" ?
                    <>
                        <div className="text-2xl font-bold text-ere-content-blue leading-[24px] w-max">Sie brauchen Hilfe?</div>
                        <div className="text-sm font-normal text-ere-grey-alternative leading-normal mt-2 ">
                            Mehr Beratung, einen eigenen Datenschutzbeauftragten, ein ausführliches Onboarding? Kein Problem, mit dem DatenschutzPro Beratungspaket inkl. DSB sind Sie rundum gut beraten.
                        </div>
                        <div className="h-20 flex-col self-center align-top text-ere-grey-alternative">
                            <div className="mt-[3px]">
                                <span className="font-semibold mt-6 text-xs">Lev Lexow </span>
                                <span className="font-normal break-keep text-xs block">TÜV-zertifizierter DSB</span>
                                <img className="w-[100px] h-auto shadow-xs inline" src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                            </div>
                        </div>
                        <img className="w-[167px] top-[294px] absolute" src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
                        <button id="erecht24-question-catalog-appointment"
                                className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 relative text-sm top-[111px] uppercase tracking-[0.233px] font-black"
                                onClick={() => openInNewTab(process.env.REACT_APP_PREMIUM_SELLING_URL)}
                        >
                            Jetzt Upgrade buchen
                        </button>
                    </>
                :
                    <>
                        <div className="text-2xl font-bold text-ere-content-blue leading-[24px] w-max">Sie haben Fragen?</div>
                        <div className="text-sm font-normal text-ere-grey-alternative leading-normal mt-2 ">
                            Kein Problem, Ihr persönlicher Datenschutzbeauftragter Lev Lexow steht Ihnen zur Verfügung.
                        </div>
                        <div className="h-20 flex-col self-center align-top text-ere-grey-alternative">
                            <div className="mt-[3px]">
                                <span className="font-semibold mt-6 text-xs">Lev Lexow </span>
                                <span className="font-normal break-keep text-xs block">TÜV-zertifizierter DSB</span>
                                <img className="w-[100px] h-auto shadow-xs inline" src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                            </div>
                        </div>
                        <img className="w-[167px] top-[280px] absolute" src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
                        <button id="erecht24-question-catalog-appointment"
                                className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 relative text-sm top-[175px] uppercase tracking-[0.233px] font-black"
                                onClick={() => openInNewTab("https://calendly.com/legaltrust")}
                        >
                            Termin vereinbaren
                        </button>
                    </>
                }
            </div>
    )

}