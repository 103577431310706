import {Link, useLocation} from "react-router-dom";
import {currentCatalog, currentQuestionCategory, selectedMenuPoint} from "../utils/breadcrumb";
import {useEffect} from "react";
import {ReactComponent as NextIcon} from "../../src/assets/icons/next-icon.svg";

export default function Breadcrumb() {
    const location = useLocation();
    useEffect(() => {}, [location.pathname, currentQuestionCategory, selectedMenuPoint, currentCatalog])

    return(
        <div className="mb-[10px] text-[14px] text-ere-grey-alternative hidden md:flex items-start md:flex-row md:items-center breadcrumb-container">
            <Link to={"/"}>
                {selectedMenuPoint.length !== 0 || currentCatalog.length !== 0 ?
                    <span>DatenschutzPro
                        <NextIcon className="inline mb-[1px] ml-[0.5px]"/>
                    </span>
                :
                    <span className="font-bold">DatenschutzPro</span>
                }
            </Link>
            <Link to={selectedMenuPoint[1]}>
                {selectedMenuPoint.length !== 0 ?
                    <>
                        {currentQuestionCategory.length !== 0 || currentCatalog.length !== 0?
                            <span>{selectedMenuPoint[0]}
                                <NextIcon className="inline mb-[1px]"/>
                            </span>
                        :
                            <span className="font-bold">{selectedMenuPoint[0]}</span>
                        }
                    </>
                :
                    ""
                }
            </Link>
            <Link to={currentQuestionCategory[1]}>
                {currentQuestionCategory.length !== 0 ?
                    <>
                        {currentCatalog.length !== 0 ?
                            <span>{currentQuestionCategory[0]}
                                <NextIcon className="inline mb-[1px]"/>
                            </span>
                        :
                            <span className="font-bold mb-[4px]">{currentQuestionCategory[0]}</span>
                        }
                    </>
                :
                    ""
                }
            </Link>
            {currentCatalog.length !== 0 ?
                <span className="font-bold mb-[3.5px]">{currentCatalog[0]}</span>
            :
                ""
            }
        </div>
    );
}