import {apiUrl, getCookieContentByName, setAccessToken, setLoggedInUser, setMaintenance} from "../helpers";

export async function fetchToken() {

    if (process.env.NODE_ENV === 'development') {
        let jwtToken = getCookieContentByName(process.env.REACT_APP_COOKIE_NAME);

        if (jwtToken === '') {
            throw new Error('eRecht24 Cookie is missing');
            // TODO: Show message in frontend -> not authenticated

        } else {
            await fetch(`${apiUrl}/login24`, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                    Stage: 'local',
                },
                method: "POST"
            })
                .then(response => response.json())
                .then((data) => {
                    setAccessToken(data.success.token);
                    setLoggedInUser(data.success.user);
                    setMaintenance(data.success.maintenance);
                })
        }
    }

    if (process.env.NODE_ENV === 'production') {
        await fetch(`${apiUrl}/login24`, {
            credentials: 'include',
            headers: {
                Accept: "application/json",
            },
            method: "POST"
        })
            .then(response => response.json())
            .then((data) => {
                setAccessToken(data.success.token);
                setLoggedInUser(data.success.user);
                setMaintenance(data.success.maintenance);
            })
    }
}